@import "../../styles/global.sass"

.App
  display: flex
  flex-direction: column
  overflow: hidden

header
  padding: 24px 24px 24px 32px
  display: flex
  align-items: center

  > .cross
    margin: 0 1rem
    font-size: 24px

  > img
    max-width: 100%
    max-height: 40px
    pointer-events: none

main
  max-width: 1100px
  min-height: calc(100vh - 320px)
  margin: 0 auto

footer
  background-color: black
  padding-top: 16px
  padding-bottom: 16px
  color: #333
  display: flex
  flex-direction: column
  align-items: center
  padding: 64px 24px

  > .social
    display: flex
    flex-direction: row
    margin-bottom: 24px

    > a
      display: flex
      padding: 8px
      border: 2px solid white
      border-radius: 22px

      &:not(:last-child)
        margin-right: 16px

      > img
        width: 24px
        height: 24px
        pointer-events: none

  > div
    font-size: 13px
    color: white
    line-height: 16px

// .result, .content
//   background-color: #EB3F42

// .button-group
//   display: inline-block

//   img
//     height: 48px
//     padding: 0 4px

h3
  font-size: 27px
  font-weight: bold

  @include mobile
    margin: 8px 0

a:hover
  opacity: 0.5

.card
  display: flex
  padding: 16px
  background-color: white
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1)
  border-radius: 24px

  .media-mask
    display: flex
    border-radius: 16px
    overflow: hidden
    transform: translateZ(0)

.description
  font-size: 17px
  line-height: 30px

.button
  display: inline-block
  font-size: 20px
  border-radius: 8px
  padding: 12px 24px
  background-color: $primary
  border: 1px solid $primary
  color: white
  text-decoration: none
  cursor: pointer

  &.outline
    background-color: white
    border: 1px solid $primary
    color: $primary

  &:disabled
    opacity: 0.5
    cursor: not-allowed

// .result
//   padding-bottom: 36px

//   > .thanks
//     font-size: 22px
//     line-height: 32px
//     padding-top: 24px

.content
  display: flex
  margin: 64px 54px 88px
  flex-direction: column

  @include desktop
    flex-direction: row

  @include mobile
    margin: 32px 27px 44px  

  > div
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    &:not(:last-child)
      margin-right: 0
      margin-bottom: 56px
      
      @include desktop
        margin-right: 56px
        margin-bottom: 0

      @include mobile
        margin-bottom: 36px

.asset
  position: relative
  display: flex
  flex-direction: column
  align-items: center

  > .left-bg
    position: absolute
    z-index: -999
    top: -50px
    left: -160px

    @include mobile
      top: -30px
      left: -100px

    > img
      width: 240px
      height: 240px

      @include mobile
        width: 160px
        height: 160px
    
  p
    text-align: center

    @include tablet-only
      max-width: 480px

    @include mobile
      max-width: 280px

  img, video
    width: 380px
    height: 380px
    object-fit: cover
    
    @include mobile
      width: 240px
      width: 240px

  .redeemed
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: white
    font-size: 16px

    > *:not(:last-child)
      margin-bottom: 12px

    > img
      width: 40px
      height: 40px

    .hint
      display: inline-block
      padding: 8px 16px
      border-radius: 4px
      background: gray
      color: white
      margin-top: 8px

.info
  position: relative
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center

  .title-center

    @include mobile
      margin-top: 32px
      text-align: center

  @include tablet-only
    max-width: 480px

  @include mobile
    max-width: 280px

  > .right-bg
    position: absolute
    z-index: -999
    right: -180px
    bottom: -200px

    @include mobile
      right: -150px
      bottom: -80px

    > img
      width: 400px
      height: 400px

      @include mobile
        width: 267px
        height: 267px

  > .download
    padding-top: 24px

  > .button-group
    padding: 24px 0 8px

    > .button
      display: inline-block
      margin-bottom: 16px

      &.desktopSignIn
        display: inline-block

        @include mobile
          display: none

      &.mobileSignIn
        display: none
        @include mobile
          display: block
          width: 100%

      @include mobile
        display: block
        text-align: center

      &:not(:last-child)
        margin-right: 16px

        @include mobile
          margin-right: 0px

#loginDialog
  border: 0
  border-radius: 12px
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.3)
  padding: 64px 32px

  &::backdrop
    background: rgba(0, 0, 0, 0.5)

  .loginButtons
    display: flex
    flex-direction: column

    .loginButton
      display: flex
      justify-content: center
      align-items: center
      font-size: 20px
      border-radius: 8px
      padding: 12px 24px
      background-color: white
      border: 1px solid $primary
      color: $primary
      text-decoration: none
      cursor: pointer

      &:hover, &:active
        background-color: $primary
        border: 1px solid
        color: white

      &:not(:last-child)
        margin-bottom: 24px

      .buttonIcon
        margin-right: 8px

// .redeem
//   padding-top: 24px
//   padding-bottom: 12px

// /* redeem-button

// .redeem-button
//   position: relative
//   display: inline-block

// .redeem-button-bg
//   width: 100%
//   height: 100%
//   position: absolute

// .redeem-button-bg1, .redeem-button-bg2
//   width: 100%
//   height: 100%
//   position: absolute
//   transform: skew(-45deg)

// .redeem-button-bg1
//   background-color: #EB3F42
//   left: 4px
//   top: 4px

// .redeem-button-bg2
//   background-color: black

// .redeem-button-text
//   position: relative
//   font-size: 20px
//   padding-top: 8px
//   padding-bottom: 8px
//   padding-left: 36px
//   padding-right: 36px
